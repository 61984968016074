// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10561(dd3b454b1c)-C17/09/2024-12:41:41-B17/09/2024-12:56:02' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10561(dd3b454b1c)-C17/09/2024-12:41:41-B17/09/2024-12:56:02",
  branch: "master",
  latestTag: "6.1",
  revCount: "10561",
  shortHash: "dd3b454b1c",
  longHash: "dd3b454b1c4896d32f961994ac5423d410aed1d7",
  dateCommit: "17/09/2024-12:41:41",
  dateBuild: "17/09/2024-12:56:02",
  buildType: "Ansible",
  } ;
